/*!
 * Utility styles
 */


 /*!
 * Style crop box edges to alert user when they are outside the image
 */
 .crop-box-edge-highlight {
    opacity: 1 !important;
    background-color: "red" !important;
    width: "3px" !important;
  }